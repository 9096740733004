exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-songwriting-body-language-tsx": () => import("./../../../src/pages/songwriting/body-language.tsx" /* webpackChunkName: "component---src-pages-songwriting-body-language-tsx" */),
  "component---src-pages-songwriting-boxes-tsx": () => import("./../../../src/pages/songwriting/boxes.tsx" /* webpackChunkName: "component---src-pages-songwriting-boxes-tsx" */),
  "component---src-pages-songwriting-hobo-wind-tsx": () => import("./../../../src/pages/songwriting/hobo-wind.tsx" /* webpackChunkName: "component---src-pages-songwriting-hobo-wind-tsx" */),
  "component---src-pages-songwriting-index-tsx": () => import("./../../../src/pages/songwriting/index.tsx" /* webpackChunkName: "component---src-pages-songwriting-index-tsx" */),
  "component---src-pages-songwriting-line-length-tsx": () => import("./../../../src/pages/songwriting/line-length.tsx" /* webpackChunkName: "component---src-pages-songwriting-line-length-tsx" */),
  "component---src-pages-songwriting-melody-tsx": () => import("./../../../src/pages/songwriting/melody.tsx" /* webpackChunkName: "component---src-pages-songwriting-melody-tsx" */),
  "component---src-pages-songwriting-move-tsx": () => import("./../../../src/pages/songwriting/move.tsx" /* webpackChunkName: "component---src-pages-songwriting-move-tsx" */),
  "component---src-pages-songwriting-number-of-lines-tsx": () => import("./../../../src/pages/songwriting/number-of-lines.tsx" /* webpackChunkName: "component---src-pages-songwriting-number-of-lines-tsx" */),
  "component---src-pages-songwriting-pov-tsx": () => import("./../../../src/pages/songwriting/pov.tsx" /* webpackChunkName: "component---src-pages-songwriting-pov-tsx" */),
  "component---src-pages-songwriting-prosody-tsx": () => import("./../../../src/pages/songwriting/prosody.tsx" /* webpackChunkName: "component---src-pages-songwriting-prosody-tsx" */),
  "component---src-pages-songwriting-rhyme-scheme-tsx": () => import("./../../../src/pages/songwriting/rhyme-scheme.tsx" /* webpackChunkName: "component---src-pages-songwriting-rhyme-scheme-tsx" */),
  "component---src-pages-songwriting-rhyme-types-tsx": () => import("./../../../src/pages/songwriting/rhyme-types.tsx" /* webpackChunkName: "component---src-pages-songwriting-rhyme-types-tsx" */),
  "component---src-pages-songwriting-six-best-friends-tsx": () => import("./../../../src/pages/songwriting/six-best-friends.tsx" /* webpackChunkName: "component---src-pages-songwriting-six-best-friends-tsx" */),
  "component---src-pages-songwriting-song-form-tsx": () => import("./../../../src/pages/songwriting/song-form.tsx" /* webpackChunkName: "component---src-pages-songwriting-song-form-tsx" */)
}

